class OneTimeCode {
  constructor(wrapper) {
    this.wrapper = wrapper;

    // Get all visible one-time-code inputs (using the attribute selector)
    this.codeInputs = Array.from(this.wrapper.querySelectorAll('input[autocomplete="one-time-code"]'));

    // Get the hidden input that holds the full code
    this.hiddenInput = this.wrapper.querySelector('input[name="verification_code"]');

    if(this.codeInputs.length > 0 && this.hiddenInput){
      this.init();
    }
  }

  init() {
    // Attach events to each OTP input
    this.codeInputs.forEach((input, index) => {
      // Handle manual input (one digit)
      input.addEventListener('input', (e) => {
        // Remove non-numeric characters
        input.value = input.value.replace(/\D/g, '');

        // Limit the value to one character
        if(input.value.length > input.maxLength) {
          input.value = input.value.slice(0, input.maxLength);
        }

        if(input.value.length === input.maxLength) {
          // If not the last input, move focus to the next input
          if(index < this.codeInputs.length - 1) {
            this.codeInputs[index + 1].focus();
          }
          else {
            // If it's the last input, submit the full code
            this.submitCode();
          }
        }
      });

      // Support pasting a full code into any input field
      input.addEventListener('paste', (e) => {
        const pastedData = e.clipboardData.getData('text');
        // Remove any non-digit characters
        const sanitizedData = pastedData.replace(/\D/g, '');
        if(sanitizedData.length === this.codeInputs.length) {
          e.preventDefault();

          // Prevent the paste event from bubbling and triggering form submission
          e.stopPropagation();

          // Populate each input field with corresponding character
          this.codeInputs.forEach((inp, idx) => {
            inp.value = sanitizedData[idx] || '';
          });

          // Focus the last input
          this.codeInputs[this.codeInputs.length - 1].focus();
          this.submitCode();
        }
      });

      // Support backspace: if input is empty, move focus to previous input
      input.addEventListener('keydown', (e) => {
        // Replace the value if input is already filled and a number key is pressed without text selected
        if(e.key.match(/^[0-9]$/) && input.value.length === 1 && input.selectionStart === input.selectionEnd) {
          e.preventDefault();

          input.value = e.key;
          if(index < this.codeInputs.length - 1) {
            this.codeInputs[index + 1].focus();
          }
          else {
            this.submitCode();
          }

          return;
        }

        // Support backspace: if input is empty, move focus to previous input
        if(e.key === 'Backspace' && input.value === '' && index > 0) {
          e.preventDefault();
          this.codeInputs[index - 1].focus();
        }
      });
    });
  }

  submitCode() {
    // Gather the full code from all inputs
    const code = this.codeInputs.map(input => input.value).join('');
    if(code.length === this.codeInputs.length) {
      if(this.hiddenInput) {
        this.hiddenInput.value = code;
      }

      // Disable the submit button and add a loading class if available
      const submitButton = this.wrapper.querySelector('button[type="submit"]');
      if(submitButton) {
        // Simulate a click so the form submission event fires (and your AJAX call in VerifyAccount.js runs)
        submitButton.click();

        submitButton.disabled = true;
        submitButton.classList.add('loading');
      }
    }
  }
}

export default OneTimeCode;
