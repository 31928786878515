import Message from "../Message";

class Modal {
  static bindAllModals() {
    const body = document.querySelector('body');
    const overlapModals = body.dataset.overlapModals == "true";
    let openmodal = document.querySelectorAll('[data-toggle="modal"]:not(.binded)');
    let noToggles = document.querySelector('[data-no-toggles]');

    if(openmodal.length > 0 || noToggles) {
      for (let i = 0; i < openmodal.length; i++) {
        const btn = openmodal[i];
        btn.classList.add("binded");

        btn.addEventListener('click', function (event) {
          event.preventDefault();

          if((btn.hasAttribute('data-conditions') && btn.classList.contains('conditions-fulfilled'))
              || !btn.hasAttribute('data-conditions')){
                Modal.toggleModal(btn.dataset.target);
          }

          else if(btn.hasAttribute('data-conditions') && !btn.classList.contains('conditions-fulfilled')){
            Message.error(btn.dataset.errorText);
          }
        })
      }
    }

    let overlays = document.querySelectorAll('.modal-overlay:not(.binded), .close-modal-button:not(.binded)');
    for (let i = 0; i < overlays.length; i++) {
      if(overlapModals) {
        overlays[i].addEventListener('click', () => Modal.toggleModal("#"+overlays[i].parentElement.id));
      } else {
        overlays[i].addEventListener('click', Modal.closeAllModals);
      }
      overlays[i].classList.add("binded");
    }

    let closemodal = document.querySelectorAll('.modal-close:not(.binded)');
    for (let i = 0; i < closemodal.length; i++) {
      if(overlapModals) {
        closemodal[i].addEventListener('click', () => Modal.toggleModal("#"+closemodal[i].closest(".modal").id));
      } else {
        closemodal[i].addEventListener('click', Modal.closeAllModals);
      }
      closemodal[i].classList.add("binded");
    }

    document.onkeydown = function (evt) {
      let isEscape = false;
      if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc")
      } else {
        isEscape = (evt.keyCode === 27)
      }
      if (isEscape && document.body.classList.contains('modal-active')) {
        Modal.closeAllModals()
      }
    };
  }

  static closeAllModals() {
    const body = document.querySelector('body');
    const modals = document.querySelectorAll('.modal:not(.no-bind-close)');
    if (modals) {
      for (let i = 0; i < modals.length; i++) {
        if (modals[i].classList.contains('active'))
          modals[i].classList.remove('active');

        //for kiosk > make sure to reset countdown if the modal is closed
        if(modals[i].classList.contains('kiosk-countdown')){
          window.kioskCountdown = window.startCountdown;
        }
        // if (!modals[i].classList.contains('pointer-events-none'))
        //   modals[i].classList.add('pointer-events-none');
      }
    }
    body.classList.remove('modal-active');

    // Trigger the callback function if it exists
    if (Modal.onModalClose) {
      Modal.onModalClose();
    }
  }

  static toggleModal(selector) {
    const body = document.querySelector('body');
    // check if another modal is opened
    if(body.classList.contains('modal-active') && body.dataset.overlapModals != "true") {
      this.closeAllModals();
    }

    const modal = document.querySelector(selector);
    if(modal){
      let active = !modal.classList.contains('prevent-close') && modal.classList.toggle('active');
      if(active){
        const elt = modal.querySelector('.focusOnOpen');
        if(elt) elt.focus();
      }
    }
    // modal.classList.toggle('pointer-events-none');
    body.classList.toggle('modal-active');

    window.dispatchEvent(new Event("resize"));
  }

  static setOnModalCloseCallback(callback) {
    Modal.onModalClose = callback;
  }

}

export default Modal;
