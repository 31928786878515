import Message from "../../Message";
import { ajax } from "../../utils";
import OneTimeCode from "../OneTimeCode";

class VerifyAccount {
  constructor() {
    this.form = document.getElementById('verify-account-form');
    if(this.form) {
      this.submitButton = this.form.querySelector('button[type="submit"]');

      this.resendButton = this.form.querySelector('#resend-verification');
      if(this.resendButton){
        this.resendButton.addEventListener('click', (e) => this.onResend(e));
      }

      if(this.submitButton){
        this.init();
      }
    }
  }

  init() {
    // Initialize the one-time code inputs
    new OneTimeCode(this.form);

    // Handle the form submission
    this.onFormSubmit();
  }

  /**
   * Handle the form submission.
   */
  onFormSubmit(){
    const oneTimeCodeInputs = this.form.querySelectorAll('input[type="text"]');

    this.form.addEventListener('submit', (e) => {
      e.preventDefault();

      const formData = new FormData(this.form);
      if(this.form.classList.contains('generate_access_token_on_success')){
        formData.append('generate_access_token_on_success', true);
      }

      ajax(this.form.action, (data) => {
        if(data.success) {
          //on thank you page, show the confirmation notice that the account has been verified
          const accountVerificationPending = document.querySelector('.thank-you-account-verification-pending');
          const accountVerificationSuccess = document.querySelector('.thank-you-account-verification-success');
          const setAPassword = document.querySelector('.thank-you-set-a-password');

          if(setAPassword){
            //set the access token as a hidden input
            if(data.token){
              const accessTokenInput = document.querySelector('input[name="set_password_access_token"]');
              if(accessTokenInput){
                accessTokenInput.value = data.token;
              }
            }

            setAPassword.classList.remove('hidden');
            accountVerificationPending.classList.add('hidden');

            //show the success message
            Message.success(data.message);
          }
          else if(accountVerificationPending && accountVerificationSuccess){
            accountVerificationPending.classList.add('hidden');
            accountVerificationSuccess.classList.remove('hidden');

            //show the success message
            Message.success(data.message);
          }
          //on checkout, reload the page on success
          else{
            window.location.reload();
          }
        }
        else{
          Message.error(data.message);

          //also add invalid class to the inputs of the one time code and remove it after a few seconds
          if(oneTimeCodeInputs){
            oneTimeCodeInputs.forEach((input) => {
              input.classList.add('invalid');

              window.setTimeout(() => {
                input.classList.remove('invalid');
              }, 3000);
            });
          }

          this.submitButton.disabled = false;
          this.submitButton.classList.remove('loading');
        }
      }, 'POST', formData, {
        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
      }, true, true);
    });
  }

  /**
   * Handle the resend button click.
   */
  onResend(e) {
    e.preventDefault();

    // Disable the button and add the loading class
    this.resendButton.disabled = true;

    // Get the original text and waiting text template from data attributes
    const originalText = this.resendButton.dataset.originalText;
    const waitingText = this.resendButton.dataset.waitingText;

    // Start a 20-second countdown
    let countdown = 20;
    this.resendButton.innerText = waitingText.replace('{0}', countdown);
    this.resendButton.classList.remove('underline');

    const intervalId = setInterval(() => {
      countdown--;
      if (countdown > 0) {
        this.resendButton.innerText = waitingText.replace('{0}', countdown);
      }
      else {
        clearInterval(intervalId);
        this.resendButton.disabled = false;
        this.resendButton.innerText = originalText;
        this.resendButton.classList.add('underline');
      }
    }, 1000);

    // Prepare and send the AJAX request
    const verificationPaymentInput = this.form.querySelector('input[name="verification_payment_intent"]');
    const paymentIntentId = verificationPaymentInput.value;

    const formData = new FormData();
    formData.append('verification_payment_intent', paymentIntentId);

    // Send the AJAX request to send another verification email
    ajax(this.resendButton.dataset.resendVerificationUrl, (data) => {
      Message.success(data.message);
    }
    , 'POST', formData, {
      "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
    }, true, true);
  }
}

export default VerifyAccount;
