import Message from "../../Message";
import { ajax } from "../../utils";
import { checkPasswordPattern } from "../Checkout/checkout-utils";

class SetAPassword{
  constructor(){
    this.form = document.querySelector('#set-a-password-form');
    if(this.form){
      this.passwordInput = this.form.querySelector('input[name="password"]');
      this.repeatPasswordInput = this.form.querySelector('input[name="repeat-password"]');
      this.submitButton = this.form.querySelector('button[type="submit"]');

      //check if the form is valid
      if(this.passwordInput && this.repeatPasswordInput){
        checkPasswordPattern(this.form, this.passwordInput);
      }

      this.init();
    }
  }

  init(){
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();

      // If both password fields exist, validate them
      if(this.passwordInput && this.repeatPasswordInput) {
        // Check if passwords match
        if(this.passwordInput.value !== this.repeatPasswordInput.value){
          Message.error(this.passwordInput.dataset.matchErrorMessage);
          this.submitButton.disabled = false;
          this.submitButton.classList.remove('loading');

          return false;
        }

        // Validate password pattern:
        if(this.passwordInput.classList.contains('invalid')){
          Message.error(this.passwordInput.dataset.invalidErrorMessage);
          this.submitButton.disabled = false;
          this.submitButton.classList.remove('loading');

          return false;
        }
      }

      const formData = new FormData(this.form);
      ajax(this.form.action, (data) => {
        if(data.success){
          const setAPassword = document.querySelector('.thank-you-set-a-password');
          const accountVerificationSuccess = document.querySelector('.thank-you-account-verification-success');

          if(accountVerificationSuccess && setAPassword){
            accountVerificationSuccess.classList.remove('hidden');
            setAPassword.classList.add('hidden');
          }
        }
        else{
          Message.error(data.message);

          this.submitButton.disabled = false;
          this.submitButton.classList.remove('loading');
        }
      }, 'POST', formData, {
        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
      }, true, true);
    });
  }
}

export default SetAPassword;
