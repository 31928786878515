const initReadMoreExtraDescription = () => {
  const extraDescriptions = document.querySelectorAll('.extra-description');
  extraDescriptions.forEach(extraDescription => {
    const seeMoreButton = extraDescription.querySelector('.extra-description__see-more');
    const dots = extraDescription.querySelector('.extra-description__dots');
    const more = extraDescription.querySelector('.extra-description__more');
    if(seeMoreButton && dots && more) {
      seeMoreButton.addEventListener('click', () => {
        dots.classList.toggle('hidden');
        more.classList.toggle('hidden');

        seeMoreButton.textContent = more.classList.contains('hidden') ? seeMoreButton.dataset.seeMore : seeMoreButton.dataset.seeLess;
      });
    }
  });
};

export { initReadMoreExtraDescription }
