import autocomplete from 'autocomplete.js';
import algoliasearch from 'algoliasearch/lite';
import { addLoadingStatusOnSearchButton } from '../common/init-search-bar';
import { getLevenshteinDistance, isMobile } from '../utils';
import addLoadingStatusSearchOnMobile from './addLoadingStatusSearchOnMobile';

const initAutocomplete = () => {
  const lang = document.body.dataset.lang.substr(0,2);
  let aroundMeTranslation = '';
  let noResultTranslation = '';

  let prefix = '';

  const client = algoliasearch("AK7I6BLE3J", "6b23703cf5bc843f0015aa31634bdeef");
  const hosts = client.initIndex(prefix + 'hosts_' + lang);
  const appellations = client.initIndex(prefix + 'appellations_' + lang);
  const regions = client.initIndex(prefix + 'regions_' + lang);
  const countries = client.initIndex(prefix + 'countries_' + lang);
  const aaContainer = document.getElementById("aa-input-container");

  const searchBarSubmit = document.querySelector(".searchBarSubmit");
  const optionsHosts = {
    hitsPerPage: 4,
  };

  const aaInput = document.querySelector(".aa-search-input");
  if(aaInput){
    aroundMeTranslation = aaInput.dataset.aroundMe;
    noResultTranslation = aaInput.dataset.noResult;
  }

  const allInputLat = document.querySelectorAll('input[name=lat]');
  const allInputLng = document.querySelectorAll('input[name=lng]');
  const allInputZoom = document.querySelectorAll('input[name=zoom]');

  //marker placed when clicking on a search bar result
  var markerPlacedBySearch;
  // Store timeout reference outside the source function for proper debouncing
  let mapboxTimeout = null;

  document.addEventListener("click", (e) => {
      if(e.target && e.target.classList.contains('aroundMe')){
          addLoadingStatusOnSearchButton();
          location.href = aaInput.dataset.aroundMeUrl;
      }
  });

  let mobileDropdownSuggestionsHostDestination = document.querySelector('.search-bar.is-mobile + .dropdown-suggestions-host-destination');

  var searchAutocomplete = autocomplete('.aa-search-input', {
    debug: isMobile(),
    hint: true,
    templates: {
      footer: `
        <div class="aroundMe cursor-pointer trigger-around-me">
          <span class="text-me-red text-center font-normal py-5 aroundMe block">${aroundMeTranslation ? aroundMeTranslation : 'See what\'s near me'}</span>
        </div>
      `,
      header: '',
      empty: `
        <div class="py-3 text-center border-b">
          <span class="text-base text-gray-500 font-normal">${noResultTranslation}</span>
        </div>
      `,
    }
  }, [
    {
      source: function(query, callback) {
        if (!query) {
          return callback([]);
        }

        // Function to normalize text by removing accents
        function normalizeString(str) {
          return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }

        // Function to check if query is a whole word within text
        function containsWholeWord(text, query) {
          // Create regex to match the query as a whole word with word boundaries
          const regex = new RegExp(`\\b${query}\\b`, 'i');
          return regex.test(text);
        }

        let allResults = [];
        let pendingRequests = 5; // 4 Algolia queries + 1 Mapbox query

        function checkComplete() {
          pendingRequests--;
          if (pendingRequests === 0) {
            // Sort results by relevance (Levenshtein distance)
            allResults.sort((a, b) => {
              // Get displayable names
              const aName = a.type === 'mapbox' ? a.text :
                        (a._highlightResult?.name?.value?.replace(/<[^>]*>/g, '') || a.name);
              const bName = b.type === 'mapbox' ? b.text :
                        (b._highlightResult?.name?.value?.replace(/<[^>]*>/g, '') || b.name);

              // Normalize all strings for comparison (remove accents)
              const normalizedQuery = normalizeString(query.toLowerCase());
              const normalizedAName = normalizeString(aName.toLowerCase());
              const normalizedBName = normalizeString(bName.toLowerCase());

              // Check for exact whole word matches (highest priority)
              const aContainsExactWord = containsWholeWord(normalizedAName, normalizedQuery);
              const bContainsExactWord = containsWholeWord(normalizedBName, normalizedQuery);

              if (aContainsExactWord && !bContainsExactWord) return -1;
              if (!aContainsExactWord && bContainsExactWord) return 1;

              // Calculate Levenshtein distances (fourth priority)
              const distA = getLevenshteinDistance(normalizedQuery, normalizedAName);
              const distB = getLevenshteinDistance(normalizedQuery, normalizedBName);

              // Primary sorting by distance
              if (distA !== distB) {
                return distA - distB;
              }

              // Secondary sorting by experience count when available
              const aCount = a.experiences_count_raw || a.experiences || 0;
              const bCount = b.experiences_count_raw || b.experiences || 0;
              return bCount - aCount;
            });

            // Limit results to 15 maximum
            if (allResults.length > 15) {
              allResults = allResults.slice(0, 15);
            }

            callback(allResults);
          }
        }

        // Get countries results
        countries.search(query, { hitsPerPage: 1 })
          .then(results => {
            results.hits.forEach(hit => {
              hit.type = 'country';
              allResults.push(hit);
            });
            checkComplete();
          })
          .catch(() => checkComplete());

        // Get regions results
        regions.search(query, { hitsPerPage: 2, numericFilters: ['displayOnSearchResults = 1'] })
          .then(results => {
            results.hits.forEach(hit => {
              hit.type = 'region';
              allResults.push(hit);
            });
            checkComplete();
          })
          .catch(() => checkComplete());

        // Get hosts results
        hosts.search(query, optionsHosts)
          .then(results => {
            results.hits.forEach(hit => {
              hit.type = 'host';
              allResults.push(hit);
            });
            checkComplete();
          })
          .catch(() => checkComplete());

        // Get appellations results
        appellations.search(query, { hitsPerPage: 2, numericFilters: ['displayOnSearchResults = 1'] })
          .then(results => {
            results.hits.forEach(hit => {
              hit.type = 'appellation';
              allResults.push(hit);
            });
            checkComplete();
          })
          .catch(() => checkComplete());

        // Get mapbox results with proper debounce
        // Clear any existing timeout to properly debounce
        if (mapboxTimeout) {
          clearTimeout(mapboxTimeout);
        }

        mapboxTimeout = setTimeout(() => {
          fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=pk.eyJ1Ijoid2luYWxpc3QiLCJhIjoiY2p0azd3b2Q5MTQzMjN5bzZidmU5cDF6biJ9.65pAPF4tbn5Geqg7JhvCFQ&limit=2&country=FR,IT,ES,PT,DE,CH,UK&types=place,address,poi`
          )
          .then((response) => response.json())
          .then((result) => {
            if (result.features) {
              // Function to escape special characters for regex
              const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
              const queryRegex = new RegExp(escapeRegExp(query), 'gi'); // 'g' for global, 'i' for case-insensitive

              result.features.forEach(feature => {
                feature.type = 'mapbox';
                // Add highlighted version of place_name
                feature.highlighted_place_name = feature.place_name.replace(queryRegex, (match) => `<em>${match}</em>`);
                allResults.push(feature);
              });
            }
            checkComplete();
          })
          .catch(() => checkComplete());
        }, 500);
      },
      displayKey: function(suggestion) {
        if (suggestion.type === 'mapbox') {
          return suggestion.place_name;
        }
        return suggestion.name;
      },
      templates: {
        suggestion(suggestion) {
          if (suggestion.type === 'country') {
            let countryTranslation = aaInput ? aaInput.dataset.country : 'Country';
            let experiencesCount = suggestion.experiences > 0 ? ' - ' + suggestion.experiences_count : '';

            return `
              <div class="text-base flex items-center justify-start text-black w-full">
                <div class="w-10 h-10 rounded-lg mr-3 bg-gray-100 border border-gray-200 flex flex-shrink-0 items-center justify-center">
                  <i class="icon icon-location"></i>
                </div>
                <div class="flex flex-col items-start whitespace-normal no-text-overflow">
                    <span class="text-md font-semibold no-text-overflow">${suggestion._highlightResult.name.value}</span>
                    <p class="text-gray-600 text-sm">${countryTranslation}${experiencesCount}</p>
                </div>
              </div>`;
          }
          else if (suggestion.type === 'region') {
            let regionTranslation = aaInput ? aaInput.dataset.region : 'Region';
            let experiencesCount = suggestion.experiences > 0 ? ' - ' + suggestion.experiences_count : '';

            return `
              <div class="text-base flex items-center justify-start text-black w-full">
                <div class="w-10 h-10 rounded-lg mr-3 bg-gray-100 border border-gray-200 flex flex-shrink-0 items-center justify-center">
                  <i class="icon icon-location"></i>
                </div>
                <div class="flex flex-col items-start whitespace-normal no-text-overflow">
                    <span class="text-md font-semibold no-text-overflow">${suggestion._highlightResult.name.value}</span>
                    <p class="text-gray-600 text-sm">${regionTranslation}${experiencesCount}</p>
                </div>
              </div>`;
          }
          else if (suggestion.type === 'host') {
            let hostTranslation = aaInput ? aaInput.dataset.host : 'Host';
            let details = `${hostTranslation}`;

            if(suggestion.city){
              details += ` • ${suggestion._highlightResult.city.value}`;
            }

            if(suggestion.experiences_count_raw > 0){
              details += ` • ${suggestion.experiences_count}`;
            }

            return `
              <div class="text-base flex items-center justify-start text-black w-full">
                  <img src="${suggestion.media_cdn_url}/cover=60x60" alt="" class="w-10 h-10 rounded-lg object-cover mr-3">
                  <div class="flex flex-col items-start whitespace-normal no-text-overflow">
                      <span class="text-md font-semibold no-text-overflow">${suggestion._highlightResult.name.value}</span>
                      <p class="text-gray-600 text-sm">${details}</p>
                  </div>
              </div>`;
          }
          else if (suggestion.type === 'appellation') {
            let appellationTranslation = aaInput ? aaInput.dataset.appellation : 'Appellation';
            return `
              <div class="text-base flex items-center justify-start text-black w-full">
                <div class="w-10 h-10 rounded-lg mr-3 bg-gray-100 border border-gray-200 flex flex-shrink-0 items-center justify-center">
                  <i class="icon icon-location"></i>
                </div>
                <div class="flex flex-col items-start whitespace-normal no-text-overflow">
                    <span class="text-md font-semibold no-text-overflow">${suggestion._highlightResult.name.value}</span>
                    <p class="text-gray-600 text-sm">${appellationTranslation}</p>
                </div>
              </div>`;
          }
          else if (suggestion.type === 'mapbox') {
            return `
              <div class="text-base flex items-center justify-start text-black w-full ${suggestion.place_type[0]}">
                <div class="w-10 h-10 rounded-lg mr-3 bg-gray-100 border border-gray-200 flex flex-shrink-0 items-center justify-center">
                  <i class="icon icon-location"></i>
                </div>
                <div class="flex flex-col name-type-wrapper">
                  <span class="text-md font-semibold">${suggestion.highlighted_place_name}</span>
                </div>
              </div>`;
          }
        }
      }
    },
    // {
    //   source: autocomplete.sources.hits(regions, { hitsPerPage: 2 }),
    //   displayKey: 'name',
    //   templates: {
    //     suggestion(suggestion) {
    //       let regionTranslation = aaInput ? aaInput.dataset.region : 'Region';
    //       let experiencesCount = suggestion.experiences > 0 ? ' - ' + suggestion.experiences_count : '';

    //       let html = `
    //               <div class="text-base flex items-center justify-start text-black w-full">
    //                 <div class="w-10 h-10 rounded-lg mr-3 bg-gray-100 border border-gray-200 flex flex-shrink-0 items-center justify-center">
    //                   <i class="icon icon-location"></i>
    //                 </div>
    //                 <div class="flex flex-col items-start whitespace-normal no-text-overflow">
    //                     <span class="text-md font-semibold no-text-overflow">${suggestion._highlightResult.name.value}</span>
    //                     <p class="text-gray-600 text-sm">${regionTranslation}${experiencesCount}</p>
    //                 </div>
    //               `;

    //       // if(suggestion.experiences > 0)
    //       //   html += `<span class="text-sm text-gray-500 ml-auto flex-shrink-0 hidden sm:block">${suggestion.experiences_count}</span>`;

    //       html += `</div>`;

    //       return html;
    //     }
    //   }
    // },
    // {
    //   source: autocomplete.sources.hits(hosts, optionsHosts),
    //   displayKey: 'name',
    //   templates: {
    //     suggestion(suggestion) {
    //       let hostTranslation = aaInput ? aaInput.dataset.host : 'Host';
    //       let experiencesCount = suggestion.experiences_count_raw > 0 ? ' - ' + suggestion.experiences_count : '';

    //       let html = `
    //         <div class="text-base flex items-center justify-start text-black w-full">
    //             <img src="${suggestion.media_cdn_url}/cover=60x60" alt="" class="w-10 h-10 rounded-lg object-cover mr-3">
    //             <div class="flex flex-col items-start whitespace-normal no-text-overflow">
    //                 <span class="text-md font-semibold no-text-overflow">${suggestion._highlightResult.name.value}</span>
    //                 <p class="text-gray-600 text-sm">${hostTranslation}${experiencesCount}</p>
    //             </div>
    //         `;

    //       // if(suggestion.experiences_count_raw > 0)
    //       //   html += `<span class="text-sm text-gray-500 ml-auto flex-shrink-0 hidden sm:block"> - ${suggestion.experiences_count}</span>`;

    //       html += `</div>`;

    //       return html;
    //     }
    //   }
    // },
    // {
    //   source: function(query, callback){
    //     fetch(
    //       `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=pk.eyJ1Ijoid2luYWxpc3QiLCJhIjoiY2p0azd3b2Q5MTQzMjN5bzZidmU5cDF6biJ9.65pAPF4tbn5Geqg7JhvCFQ&limit=2&country=FR,IT,ES,PT,DE,CH&types=place,address,poi`
    //     )
    //     .then((response) => response.json())
    //     .then((result) => {
    //       callback(result.features);

    //       const mapboxDatasets = document.querySelectorAll('.aa-dataset-mapbox');
    //       if(mapboxDatasets){
    //         mapboxDatasets.forEach(dataset => {
    //           dataset.classList.remove('hidden');
    //         });
    //       }

    //       //Hide addresses and POI if there are no spaces
    //       const addressesSuggestions = document.querySelectorAll('.algolia-autocomplete:not(.show-addresses) .address, .algolia-autocomplete:not(.show-addresses) .poi');
    //       if(addressesSuggestions){
    //         addressesSuggestions.forEach(suggestion => {
    //           suggestion.parentNode.classList.add('hidden');
    //         });

    //         const mapboxDataset = document.querySelectorAll('.algolia-autocomplete:not(.show-addresses) .aa-dataset-mapbox');
    //         mapboxDataset.forEach(dataset => {
    //           const notHiddenSuggestions = dataset.querySelectorAll('.aa-suggestion:not(.hidden)');
    //           if(notHiddenSuggestions.length === 0){
    //             dataset.classList.add('hidden');
    //           }
    //         });
    //       }
    //     });
    //   },
    //   debounce: 500,
    //   name: 'mapbox',
    //   displayKey: function(suggestion) {
    //     return suggestion.place_name;
    //   },
    //   templates: {
    //     suggestion(suggestion) {
    //       return `
    //         <div class="text-base flex items-center justify-start text-black w-full ${suggestion.place_type[0]}">
    //           <div class="w-10 h-10 rounded-lg mr-3 bg-gray-100 border border-gray-200 flex flex-shrink-0 items-center justify-center">
    //             <i class="icon icon-location"></i>
    //           </div>
    //           <div class="flex flex-col name-type-wrapper">
    //             <span class="text-md font-semibold">${suggestion.place_name}</span>
    //           </div>
    //         </div>
    //       `;
    //     }
    //   }
    // },
    // {
    //   source: autocomplete.sources.hits(appellations, { hitsPerPage: 2 }),
    //   displayKey: 'name',
    //   name: 'appellations',
    //   templates: {
    //     // header: '<div class="aa-suggestions-category">Hosts</div>',
    //     suggestion(suggestion) {
    //       let appellationTranslation = aaInput ? aaInput.dataset.appellation : 'Appellation';
    //       return `
    //         <div class="text-base flex items-center justify-start text-black w-full">
    //           <div class="w-10 h-10 rounded-lg mr-3 bg-gray-100 border border-gray-200 flex flex-shrink-0 items-center justify-center">
    //             <i class="icon icon-location"></i>
    //           </div>
    //           <div class="flex flex-col items-start whitespace-normal no-text-overflow">
    //               <span class="text-md font-semibold no-text-overflow">${suggestion._highlightResult.name.value}</span>
    //               <p class="text-gray-600 text-sm">${appellationTranslation}</p>
    //           </div>
    //         </div>
    //         `;
    //     }
    //   }
    // }
  ]).on('autocomplete:selected autocomplete:autocompleted', function(event, suggestion, dataset, context) {

    addLoadingStatusSearchOnMobile()

    //If it's an api call from maplibre
    if(suggestion.hasOwnProperty('geometry')){
      if(allInputLat) {
        allInputLat.forEach(input => {
          input.value = suggestion.geometry.coordinates[1]; //lat
        });
      }
      if(allInputLng) {
        allInputLng.forEach(input => {
          input.value = suggestion.geometry.coordinates[0]; //lng
        });
      }
      if(allInputZoom) {
        allInputZoom.forEach(input => {
          input.value = 12;
        });
      }

      if(window.map){
        mapIsFlying = true;
        map.flyTo({
          center: [suggestion.geometry.coordinates[0], suggestion.geometry.coordinates[1]],
          zoom: 12,
          speed: 2.5
        });

        if(markerPlacedBySearch)
          markerPlacedBySearch.remove();

        let div = document.createElement('div');
        div.className = 'search-result-marker';
        markerPlacedBySearch = new maplibregl.Marker({
          element: div
        })
        .setLngLat([suggestion.geometry.coordinates[0], suggestion.geometry.coordinates[1]])
        .addTo(map);
      }
    }
    else if(suggestion.hasOwnProperty('_geoloc') && window.map){
      mapIsFlying = true;
      map.flyTo({
        center: [suggestion._geoloc.lng, suggestion._geoloc.lat],
        zoom: suggestion.zoom ?? 12
      });

      if(markerPlacedBySearch)
        markerPlacedBySearch.remove();
    }

    if(aaContainer !== undefined){
        addLoadingStatusOnSearchButton();
        if(suggestion.hasOwnProperty('url')){
          //in case the url contains /s/, remove it since we don't want to redirect to /s but to the locations landings
          let url = suggestion.url.replace("/s/", '/');

          //workaround for staging > TODO MELCHIOR remove
          if(document.body.dataset.env == 'staging'){
            url = url.replace("www.winalist.fr", 'staging.winalist.fr');
            url = url.replace("www.winalist.com", 'staging.winalist.com');
          }

          window.location.assign(url);
        }
        else{
          let url = encodeURI(aaContainer.dataset.urlSearch
          .replace('setlat', suggestion.geometry.coordinates[1])
          .replace('setlng', suggestion.geometry.coordinates[0])
          .replace('setdestination', suggestion.place_name))
          .replace('setlocation', suggestion.text);

          if(document.body.dataset.env == 'staging'){
            url = url.replace("www.winalist.fr", 'staging.winalist.fr');
            url = url.replace("www.winalist.com", 'staging.winalist.com');
          }

          window.location.assign(url);
        }
    }

    // Do nothing on click, as the browser will already do it
    if (context.selectionMethod === 'click') {
      if(searchBarSubmit){
        searchBarSubmit.disabled = false;
      }
      return;
    }
  })
  // when focus on input, show the dropdown
  .on('autocomplete:shown', function(event, suggestion, dataset, context) {
    mobileDropdownSuggestionsHostDestination?.classList.add('hidden');
  })
  .on('autocomplete:closed', function(event, suggestion, dataset, context) {
    mobileDropdownSuggestionsHostDestination?.classList.remove('hidden');
  });

  let aaDropdownsMenu = document.querySelectorAll('.aa-dropdown-menu');
  aaDropdownsMenu.forEach(aaDropdownMenu => {
    let searchBar = aaDropdownMenu.closest('.search-bar');
    if (searchBar && searchBar.classList.contains('is-mobile')) {
      aaDropdownMenu.classList.add('is-mobile');

      // When dropdown autocomplete is closed and the input is empty, show the dropdown recently viewed
      let input = searchBar.querySelector('input[name="mobile_search_bar"]');
      if (input) {
        input.addEventListener('input', () => {
          if (input.value.length === 0) {
            mobileDropdownSuggestionsHostDestination?.classList.remove('hidden');
          }
        });
      }
    }

    // Move dropdown algolia to the end of the search bar
    searchBar?.appendChild(aaDropdownMenu);
  });

  window.searchAutocomplete = searchAutocomplete;

  const aaInputs = document.querySelectorAll(".aa-search-input");
  aaInputs.forEach(function(input){
    input.addEventListener('keyup', function(){
      let length = input.value.length;
      if(length >= 4){
        input.parentNode.classList.add('show-appellations'/*, 'show-experiences'*/);
      } else {
        input.parentNode.classList.remove('show-appellations'/*, 'show-experiences'*/);
      }

      if(input.value.indexOf(' ') !== -1){
        input.parentNode.classList.add('show-addresses');
      } else {
        input.parentNode.classList.remove('show-addresses');
      }

      // Select the dropdown recently viewed
      const searchBar = input.closest('.search-bar');
      if(searchBar){
        let dropdownRecentlyViewed = searchBar.querySelector('.dropdown-suggestions-host-destination');
        dropdownRecentlyViewed?.classList.toggle('show', length === 0);
      }
    });
  });
};

export { initAutocomplete };
